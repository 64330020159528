import type { ApiError } from '../../api'
import { isApiError } from '../../api'

export type PasswordRule = {
    text: string
    valid: boolean
}

export const isChangePasswordValidationError = (
    error: unknown,
): error is ApiError<{ non_field_errors: ReadonlyArray<string> }> => {
    if (isApiError(error)) {
        // eslint-disable no-unsafe-optional-chaining
        const responseData = (error.response?.data as { non_field_errors: ReadonlyArray<unknown> }).non_field_errors

        return Array.isArray(responseData) && responseData.every((item) => typeof item === 'string')
    }

    return false
}
