import { useReducer, useState } from 'react'

import { Box } from '@adverity/design-system/components/box'
import { Checkbox } from '@adverity/design-system/components/checkbox'
import { Field, FieldWithoutLabel } from '@adverity/design-system/components/field'
import { Input } from '@adverity/design-system/components/input'
import { Stack } from '@adverity/design-system/components/stack'
import { zodResolver } from '@hookform/resolvers/zod'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Errors } from './errors'
import { PasswordRules } from './password-rules'
import { useChangePasswordMutation } from '../queries'
import { isChangePasswordValidationError } from '../utils'

// When using mounts in django admin pages it is applying django-admin/base.less, which is changing default hr styling
import './django-admin-reset.css'

const minLength = 16
const notOnlyNumericRegex = /(?!^\d+$)^.+$/
const oneNumberRegex = /\d/

const getPasswordPolicy = () => ({
    minLength: `At least ${minLength} characters`,
    notOnlyNumeric: 'At least 1 non-numeric character',
    oneNumber: 'At least 1 number',
    uppercase: 'At least 1 uppercase character',
    lowercase: 'At least 1 lower case character',
    specialChar: 'At least 1 special character',
    passwordMatch: 'Passwords must match',
})

const errorMessages = {
    passwordsMismatch: 'Passwords are not the same',
}
const getSchema = () => {
    const passwordPolicy = getPasswordPolicy()

    return z
        .object({
            newPassword: z
                .string()
                .min(minLength, passwordPolicy.minLength)
                .regex(notOnlyNumericRegex, passwordPolicy.notOnlyNumeric)
                .regex(oneNumberRegex, passwordPolicy.oneNumber),
            confirmPassword: z.string(),
        })
        .refine((data) => data.newPassword === data.confirmPassword, {
            message: errorMessages.passwordsMismatch,
            path: ['confirmPassword'],
        })
}

const schema = getSchema()

type Schema = z.infer<typeof schema>

type Props = {
    token: string
    uidb64: string
}

// eslint-disable-next-line complexity
export const PasswordResetForm = (props: Props) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { isValid, errors },
    } = useForm<Schema>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            newPassword: '',
            confirmPassword: '',
        },
        criteriaMode: 'all',
    })

    const [apiErrors, setApiErrors] = useState<ReadonlyArray<string>>([])
    const [passwordVisible, togglePasswordVisible] = useReducer((previous) => !previous, false)
    const changePassword = useChangePasswordMutation(
        {
            password: watch('newPassword'),
            token: props.token,
            uidb64: props.uidb64,
        },
        {
            onError: (error) => {
                if (isChangePasswordValidationError(error)) {
                    setApiErrors(error.response?.data.non_field_errors ?? [])
                }
            },
        },
    )

    const passwordFieldType = passwordVisible ? 'text' : 'password'

    const submitForm: SubmitHandler<Schema> = () => {
        setApiErrors([])

        changePassword.mutate()
    }

    if (props.token === '' || props.uidb64 === '') {
        return <p>The password reset link was invalid. Please request a new password reset.</p>
    }
    const passwordPolicy = getPasswordPolicy()

    const passwordRules = [
        {
            text: passwordPolicy.minLength,
            valid: watch('newPassword').length > 0 && !errors.newPassword?.types?.too_small,
        },
        {
            text: passwordPolicy.notOnlyNumeric,
            valid: watch('newPassword').length > 0 && notOnlyNumericRegex.test(watch('newPassword')),
        },
        {
            text: passwordPolicy.oneNumber,
            valid: watch('newPassword').length > 0 && oneNumberRegex.test(watch('newPassword')),
        },
        {
            text: passwordPolicy.passwordMatch,
            valid: watch('confirmPassword').length > 0 && !errors.confirmPassword?.types?.custom,
        },
    ]

    return (
        <Box as="form" onSubmit={handleSubmit(submitForm)}>
            <Stack maxWidth={300} marginY="x-small" marginX="auto">
                <Field fullWidth label="New password">
                    <Input type={passwordFieldType} {...register('newPassword')} />
                </Field>
                <Field fullWidth label="Confirm Password">
                    <Input type={passwordFieldType} {...register('confirmPassword')} />
                </Field>
                <FieldWithoutLabel fullWidth orientation="horizontal">
                    <Checkbox checked={passwordVisible} label="Show passwords" onChange={togglePasswordVisible} />
                </FieldWithoutLabel>
                <PasswordRules rules={passwordRules} />
                {apiErrors.length > 0 && <Errors errors={apiErrors} />}
                <button type="submit" disabled={!isValid || changePassword.isPending}>
                    Save Password
                </button>
            </Stack>
        </Box>
    )
}
